<template>
  <v-container>
    <div class="text-center mb-5" style="margin-top: 150px">
      <img src="@/assets/logo.png" width="200" />
    </div>
    <v-card
      elevation="0"
      min-width="300"
      max-width="500"
      style="margin: 0 auto 200px"
    >
      <v-card-title class="justify-center">
        <h2 class="my-5">لوحة التحكم</h2>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="رقم الهاتف"
            required
            class="mb-10 font-weight-bold"
            :disabled="checking"
          >
          </v-text-field>
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            label="كلمة المرور *"
            type="password"
            required
            class="mb-5 font-weight-bold"
            :disabled="checking"
          ></v-text-field>
          <!-- <div class="text-start">
            <router-link
              to="/forgetPassword"
              class="text-decoration-underline black--text"
              dark
              >نسيت كلمة المرور</router-link
            >
          </div> -->

          <div class="d-flex justify-center mt-10">
            <v-btn
              :disabled="!valid"
              :loading="checking"
              rounded
              color="primary"
              @click="validate"
              class="login__btn"
              width="180"
              height="40"
            >
              دخول
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>

    <v-snackbar width="auto" right v-model="snackbar.prop">
      {{ snackbar.err ? snackbar.errText : snackbar.succText }}
      <template v-slot:action="{ attrs }">
        <v-icon
          :color="snackbar.err ? snackbar.errColor : snackbar.succColor"
          v-bind="attrs"
          >{{ snackbar.err ? "highlight_off" : "check_circle" }}</v-icon
        >
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    checking: false,
    trans: {
      categories: {},
    },
    snackbar: {
      prop: false,
      err: true,
      errColor: "red lighteen-2",
      succColor: "success",
      errText: "رقم الهاتف او كلمة المرور غير صحيحة",
      succText: "تم تسجيل الدخول بنجاح",
    },
    valid: true,
    email: "",
    emailRules: [(v) => !!v || "رقم الهاتف مطلوب"],
    password: "",
    passwordRules: [(v) => !!v || "كلمة المرور مطلوبة"],
  }),
  components: {

  },
  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.checking = true;
        this.$store
          .dispatch("login", {
            phone: this.email,
            password: this.password,
          })
          .then((res) => {
            this.snackbar.prop = true;
            this.snackbar.err = false;
            setTimeout(() => {
              this.$router.push({
                name: "Dashboard",
              });
            }, 1000);
            console.log(res);
          })
          .catch((err) => {
            if (!err.response) {
              this.$store.state.connection_snack = true;
            } else {
              this.snackbar.prop = true;
              this.snackbar.err = true;
              this.checking = false;
            }
          });
      }
    },
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
  created() {
    let vm = this;
    window.onkeyup = function (e) {
      // make delete action in enter key
      if (e.key == "Enter") {
        vm.validate();
      }
    };
  },
};
</script>
